<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12" id="testseries">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title w-full d-flex justify-content-between">
              <div>
                <i class="fa fa-solid fa-graduation-cap" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
                {{cvCardTitle}}
              </div>
              <div>
                <b-button style="float:right;" variant="primary" @click="goToTestPrepPdtPage" v-if="moreBatchesToBuy && userData.user_role == 'USERROLE11114'">{{cvbtnGidePrep}}</b-button>
                <b-button variant="primary" @click="goToGIDEPrepDashboard" class="m-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">{{cvbtnGIDEPrepDashboard}}</b-button>
              </div>
            </h4>
            <p style="color:#e5252c;" v-if="userDetails">
              <span>Name:&nbsp;{{userDetails.user_name}}</span><br>
              <span>Email:&nbsp;{{userDetails.user_email}}</span><br>
              <span v-if="userDetails.user_mobile">Mobile:&nbsp;{{userDetails.user_mobile}}</span>
            </p>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="subjModuleListObj && subjModuleListObj.length > 0">
                <b-table
                  hover
                  responsive
                  :items="subjModuleListObj"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(id)="data">
                    <span>{{subjModuleListObj.indexOf(data.item)+1}}</span>
                  </template>

                  <template v-slot:cell(avg_score_percentage)="data">
                    <span>{{data.item.avg_score_percentage ? data.item.avg_score_percentage.toFixed(2) : "0"}}%</span>
                  </template>

                  <template v-slot:cell(subject_module_name)="data">
                    <span class="">{{data.item.subject_module_name}}</span><br>
                    <button class="actionIcon btn mt-1 primary-color" title="Take Assessment" @click="goToAppDetail(data.item.app_id)" v-if="data.item.app_id"><i class="ri-question-answer-fill font-size-20 p-1"></i></button>
                    <button class="actionIcon btn mt-1 primary-color" title="View Assessment Result" @click="goToResults(data.item.app_id)" v-if="data.item.app_id"><i class="ri-file-chart-fill font-size-20 p-1"></i></button>
                    <!-- <button class="actionIcon btn mt-1 primary-color" title="Analyse Assessment" @click="getResultAnalysis(data.item)" v-if="data.item.app_id"><i class="ri-search-eye-line font-size-20 p-1"></i></button> -->
                  </template>

                  <template v-slot:cell(subject_name)="data">
                    <b-badge class="pointer ml-1" size="sm" :style="getStateColorCode(data.item.subject_name)">{{data.item.subject_name }}</b-badge>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    </b-container>
    </template>

<script>
import { TPrepSubjectModules } from "../../../FackApi/api/TPrepSubjectModule"
import { socialvue } from "../../../config/pluginInit"
import { TprepSessions } from "../../../FackApi/api/TprepSession"
import TestSeriesConfigJson from "../../../FackApi/json/TestSeries.json"
import { User } from "../../../FackApi/api/user"

export default {
  name: "TPrepModuleList",
  data () {
    return {
      TestSeriesConfigJson: TestSeriesConfigJson,
      apiName: "tprep_subject_module_report_list",
      subjectId: null,
      subjModuleListObj: [],
      subjectModuleResultListRespObj: [],
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Module", key: "subject_module_name", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Subject", key: "subject_name", class: "text-left align-text-top w-200px", sortable: true },
        { label: "No. of Attempts", key: "no_of_attempts", class: "text-left align-text-top", sortable: true },
        { label: "Avg Score (%)", key: "avg_score_percentage", class: "text-left align-text-top", sortable: true }
      ],
      cvSearchText: "Type to Search",
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 200,
      cvCardTitle: `TestSeries ${this.$route.params.subjId.split("TESTSERIES_")[1]} Modules`,
      sortBy: "name",
      sortDesc: false,
      userDetails: null,
      cvbtnGidePrep: "Add (+)",
      cvbtnGIDEPrepDashboard: "TestSeries Dashboard",
      moreBatchesToBuy: false,
      userSubjectInterest: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()

    this.subjectId = this.$route.params.subjId
    await this.getUserSubjectInterest()
    this.checkPayment()
    this.getModuleList(this.subjectId)
  },
  methods: {
    /**
     * getUserSubjectInterest
     */
    async getUserSubjectInterest () {
      try {
        const userInterestListResp = await User.userInterestList(this, this.userData.user_id)
        if (!userInterestListResp) {
          this.$router.back()
          return
        }
        this.userSubjectInterest = userInterestListResp.resp_data.interest_list.INTERESTCAT11117.map(e => e.interest_id)
      }
      catch (err) {
        console.error("Exception in getUserSubjectInterest and err: ", err)
      }
    },
    /**
     * getModuleList
     */
    async getModuleList () {
      const payload = {
        interest_id: this.subjectId
      }
      if (this.$route.query.gide_user_id) {
        payload.gide_user_id = this.$route.query.gide_user_id
      }
      const subjectModuleListResp = await TPrepSubjectModules.tprepSubjectModuleReportList(this, payload)

      if (subjectModuleListResp && subjectModuleListResp.resp_status) {
        this.subjModuleListObj = subjectModuleListResp.resp_data.data
        let attemptsData = subjectModuleListResp.attempts_data

        if (attemptsData) {
          for (let i of this.subjModuleListObj) {
            let attempts = attemptsData.find(e => e.app_id == i.app_id)
            if (attempts) {
              i.no_of_attempts = attempts.no_of_attempts
              i.avg_score_percentage = attempts.avg_score_percentage
            }
          }
        }
        this.totalRows = subjectModuleListResp.resp_data_count
        this.userDetails = subjectModuleListResp.user_details
      }
    },
    /**
     * checkPayment
     */
    async checkPayment () {
      try {
        // check if the user has a session, if it has then redirect the user to teest_prep
        let hasUserPaid = await TprepSessions.tprep_sessionCheckPayment(this, { gide_user_id: this.$route.query.gide_user_id ? this.$route.query.gide_user_id : this.userData.user_id, user_subject_interest: this.userSubjectInterest })
        if (!hasUserPaid.resp_status) {
          this.$router.back()
        }
        else if (hasUserPaid.modules_not_purchased.length > 0) {
          this.moreBatchesToBuy = false
        }
      }
      catch (error) {
        console.error("Exception in checkPayment and err: ", error)
      }
    },
    /**
     * goTo Quiz/App Details
     */
    goToAppDetail (appId) {
      if (appId) {
        this.$router.push(`/quiz_detail/${appId}`)
      }
    },
    /**
     * goTo Quiz/App Results
     */
    goToResults (appId) {
      if (appId && this.$route.query.gide_user_id) {
        this.$router.push(`/result_list/${appId}?gide_user_id=${this.$route.query.gide_user_id}`)
      }
      else if (appId) {
        this.$router.push(`/result_list/${appId}`)
      }
    },
    /**
     * goToTestPrepPdtPage
     */
    goToTestPrepPdtPage () {
      this.$router.push(`/test_prep_target/${TestSeriesConfigJson.TESTSERIES_SAT_PRODUCT_PAGE_APP_ID}`)
    },
    /**
     * goToGIDEPrepDashboard
     */
    goToGIDEPrepDashboard () {
      this.$router.push("/tpretestseries_dashboard")
    },
    /**
     * getResultAnalysis
     */
    async getResultAnalysis (result) {
      this.$router.push(`/analyse_app_result/${result.app_id}/${this.userData.user_id}`)
    },
    /**
     * get State ColorCode
     */
    getStateColorCode (name = null) {
      if (name.toLowerCase().includes("mock")) {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
      else {
        return `vertical-align: middle; color: #FFF; background: #FF9900 !important;`
      }
    }
  }
}
</script>

<style>
  .w-full{
    width: 100%;
  }
</style>
